import gsap from "gsap";
import { CustomEase } from "gsap/CustomEase";
import ScrollTrigger from "gsap/ScrollTrigger"; // ScrollTrigger を追加

gsap.registerPlugin(CustomEase, ScrollTrigger);

export function fv() {
    const topFv = document.querySelector(".p-fv");
    if (topFv != null) {
        // HTMLのテキストを1文字ずつタグで囲う
        let texts01 = document.querySelectorAll(".js-text-animation-fv01");
        texts01.forEach((text) => {
            let concatStr = "";
            let str = text.innerHTML.trim();
            for (let c of str) {
                c = c.replace(/\s+/, "&thinsp;");
                concatStr = concatStr + `<span class="fv-char01" aria-hidden="true" translate="no">${c}</span>`;
            }
            text.innerHTML = concatStr;
        });
        let texts02 = document.querySelectorAll(".js-text-animation-fv02");
        texts02.forEach((text) => {
            let concatStr = "";
            let str = text.innerHTML.trim();
            for (let c of str) {
                c = c.replace(/\s+/, "&thinsp;");
                concatStr = concatStr + `<span class="fv-char02" aria-hidden="true" translate="no">${c}</span>`;
            }
            text.innerHTML = concatStr;
        });
        gsap.set([".fv-char01", ".fv-char02"], {
            display: "inline-block", // transformさせるには必須
        });
        CustomEase.create("custom", "0.18,0.06,0.23,1");

        gsap.timeline({
            delay: 2,
            defaults: {
                duration: 1,
                ease: "power3.out",
            },
        })
            .set(".p-fv__copy", {
                autoAlpha: 1,
            })
            .to(".p-fv__bg", {
                autoAlpha: 1,
                x: 0,
            })
            .to(
                ".p-fv__video-inner",
                {
                    autoAlpha: 1,
                    x: 0,
                },
                "<+0.3"
            )
            .to(
                ".p-fv__figure-01",
                {
                    "clip-path": "polygon(200% 0%, 0% 200%, 0% 0%)",
                    duration: 2,
                },
                "<+0.2"
            )
            .to(
                ".p-fv__figure-02",
                {
                    "clip-path": "polygon(0% -100%, 200% 100%, 0% 100%)",
                    duration: 2,
                },
                "<+0.1"
            )
            .to(
                ".p-fv__figure-03",
                {
                    "clip-path": "polygon(200% 0%, 0% 200%, 0% 0%)",
                    duration: 2,
                },
                "<+0.1"
            )
            //video終了後にヘッダー
            .to(
                ".header",
                {
                    y: -20,
                    autoAlpha: 1,
                    ease: "power4.out",
                },
                "-=1"
            )
            .from(
                ".header li",
                {
                    // y: -20,
                    scale: 1.05,
                    opacity: 0,
                    ease: "power4.out",
                    stagger: 0.2,
                },
                "<+0.5"
            )
            .from(
                ".fv-char01",
                {
                    y: "100%",
                    scaleY: 0,
                    stagger: {
                        each: 0.03,
                    },
                },
                "<"
            )
            .from(
                ".fv-char02",
                {
                    y: "100%",
                    scaleY: 0,
                    stagger: {
                        each: 0.05,
                    },
                },
                "-=1.2"
            );
    } else {
        // 何らかの処理
    }
}
