import { Splide } from "@splidejs/splide";
// import { AutoScroll } from "@splidejs/splide-extension-auto-scroll";
export function slideProduct() {
    const eventSlider = document.querySelector("#splide-product");
    if (eventSlider) {
        new Splide(eventSlider, {
            type: "loop", // ループ
            arrows: false, // 矢印ボタンを非表示
            rewind: true,
            autoplay: true,
            focus: "center", // アクティブなスライドを中央にする
            updateOnMove: true, // is-activeクラスを移動前に更新する
            padding: "10%", // スライダーの左右の余白
            gap: 20,
            interval: 3000,
            perPage: 3, // 表示するスライドの枚数
            breakpoints: {
                768: {
                    perPage: 1, // 500px未満の表示枚数
                    padding: "0%", // スライダーの左右の余白
                },
            },
            wheel: true,
			wheelSleep: 1000,
        }).mount();
    }
}
