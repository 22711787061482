import { gsap } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollToPlugin);
gsap.registerPlugin(ScrollTrigger);

gsap.registerPlugin(ScrollToPlugin);
export function scrollToTop() {
    // フッターの要素を取得
    const footerElement = document.getElementById("footer");
    // スクロールトップボタンの要素を取得
    const scrollToTopElement = document.getElementById("js-scrollToTop");

    if (scrollToTopElement != null && footerElement != null) {
        // 最初に要素を非表示に設定
        scrollToTopElement.style.display = "none";

        // スクロールイベントリスナーを追加
        window.addEventListener("scroll", function() {
            // 現在のスクロール位置を取得
            const scrollPosition = window.scrollY || document.documentElement.scrollTop;
            // フッターの位置を取得
            const footerPosition = footerElement.offsetTop;

            // スクロール位置が500pxを超えたら表示、それ以下なら非表示
            if (scrollPosition > 400) {
                scrollToTopElement.style.display = "block";
            } else {
                scrollToTopElement.style.display = "none";
            }

            // スクロール位置がフッターに到達したらクラスを追加、それ以外は削除
            if (scrollPosition + window.innerHeight >= footerPosition) {
                scrollToTopElement.classList.add("change");
            } else {
                scrollToTopElement.classList.remove("change");
            }
        });
    }
    // 既存のクリックイベントリスナー（アニメーションのための）
    document.getElementById("js-scrollToTop").addEventListener("click", () => {
        gsap.to(window, {
            duration: 1,
            scrollTo: {
                y: 0,
                autoKill: false
            }
        });
    });
}