import { gsap } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollToPlugin, ScrollTrigger);

export function animation() {
    window.addEventListener("DOMContentLoaded", function() {
        // トップビジネスセクションの横スクロールアニメーション
        const businessPinArea = document.querySelectorAll(".js-top-business-pin");
        if (businessPinArea != null) {
            // 水平スクロール
            const businessItemsWrap = document.querySelector(".p-top__business-lists");
            const businessContent = document.querySelector(".p-top__business-content");
            const pagers = gsap.utils.toArray(".p-top__business-pager span");
            let thresholds = [];
            const threshold = 100 / (pagers.length + 1);
            for (let i = 1; i < pagers.length; i++) {
                thresholds.push(threshold * i);
            } // 閾値を配列に格納
            let moveX = 0;
            let start = "";
            let horizontalMM = gsap.matchMedia();
            // デスクトップ
            horizontalMM.add("(min-width:769px)", function() {
                moveX = -(businessItemsWrap.clientWidth - businessContent.clientWidth + 40);
                start = "top+=70 top";
            });
            // モバイル
            horizontalMM.add("(max-width:768px)", function() {
                moveX = -(businessItemsWrap.clientWidth - businessContent.clientWidth + 32);
                start = "top+=50 top";
            });
            gsap.to(businessItemsWrap, {
                x: moveX,
                ease: "none",
                scrollTrigger: {
                    trigger: businessPinArea,
                    start: start,
                    end: `+=${businessItemsWrap.clientWidth}`,
                    scrub: true,
                    pin: true,
                    anticipatePin: 1,
                    invalidateOnRefresh: true,
                    markers: false,
                    onUpdate: (self) => {
                        let percentage = self.progress.toLocaleString(undefined, {
                            maximumFractionDigits: 3,
                        }); //百分率
                        percentage = Math.ceil(percentage * 100); // 整数にする
                        for (let i = 0; i <= thresholds.length; i++) {
                            if (percentage >= thresholds[i]) {
                                // 閾値によって表示するものを変化
                                pagers.forEach((item) => {
                                    item.classList.remove("is-current");
                                });
                                if (self.direction === 1) {
                                    pagers[i + 1].classList.add("is-current");
                                } else {
                                    pagers[i].classList.add("is-current");
                                }
                            }
                        }
                    },
                },
            });
        }
        // フッター TOPスクロールボタン
        const scrollTopElement = document.getElementById("js-scrollTop");
        if (scrollTopElement != null) {
            // 最初に要素を非表示に設定
            scrollTopElement.style.display = "none";

            // スクロールイベントリスナーを追加
            window.addEventListener("scroll", function() {
                // 現在のスクロール位置を取得
                const scrollPosition = window.scrollY || document.documentElement.scrollTop;

                // スクロール位置が500pxを超えたら表示、それ以下なら非表示
                if (scrollPosition > 500) {
                    scrollTopElement.style.display = "block";
                } else {
                    scrollTopElement.style.display = "none";
                }
            });

            // 既存のクリックイベントリスナー（アニメーションのための）
            scrollTopElement.addEventListener("click", () => {
                gsap.to(window, {
                    duration: 1,
                    scrollTo: {
                        y: 0,
                        autoKill: false,
                    },
                });
            });
        }

        // フェードインアニメーション
        const fadeInElementsBg = document.querySelectorAll(".js-fade-in-bg");
        const targetBg = document.querySelector(".p-top__service");
        fadeInElementsBg.forEach((element, index) => {
            gsap.fromTo(
                element, {
                    opacity: 0,
                    y: 70,
                }, {
                    opacity: 1,
                    y: 0,
                    duration: .7,
                    ease: "power1.out",
                    scrollTrigger: {
                        trigger: targetBg, // トリガーとなる要素を指定
                        start: "center +=400px", // スクロール開始位置を指定
                        // markers: true,
                    },
                }
            );
        });

        // フェードインアニメーション
        const fadeInElements = document.querySelectorAll(".js-fade-in");
        fadeInElements.forEach((element, index) => {
            gsap.fromTo(
                element, {
                    opacity: 0,
                    y: 70,
                }, {
                    opacity: 1,
                    y: 0,
                    duration: .7,
                    ease: "power1.out",
                    scrollTrigger: {
                        trigger: element, // トリガーとなる要素を指定
                        start: "top-=40 80%", // スクロール開始位置を指定
                        // markers: true,
                    },
                    delay: index * .5 // 1秒間隔でアニメーションを開始
                }
            );
        });

        // フェードインアニメーション
        const fadeInElementsNews = document.querySelectorAll(".js-fade-in-news");
        const TargetNews = document.querySelectorAll(".p-top__news");
        fadeInElementsNews.forEach((element, index) => {
            gsap.fromTo(
                element, {
                    opacity: 0,
                    y: 70,
                }, {
                    opacity: 1,
                    y: 0,
                    duration: .7,
                    ease: "power1.out",
                    scrollTrigger: {
                        trigger: element, // トリガーとなる要素を指定
                        start: "top+=40 80%", // スクロール開始位置を指定
                        //markers: true,
                    },
                    delay: index * .5 // 1秒間隔でアニメーションを開始
                }
            );
        });

        const fadeInLeftElements = document.querySelectorAll(".js-fade-in-left");
        fadeInLeftElements.forEach((element) => {
            gsap.fromTo(
                element, {
                    opacity: 0,
                    x: -30,
                }, {
                    opacity: 1,
                    x: 0,
                    duration: 1,
                    ease: "power1.out",
                    scrollTrigger: {
                        trigger: element, // トリガーとなる要素を指定
                        start: "top 80%", // スクロール開始位置を指定
                        //markers: true,
                    },
                }
            );
        });

        const fadeInRightElements = document.querySelectorAll(".js-fade-in-right");
        fadeInRightElements.forEach((element) => {
            gsap.fromTo(
                element, {
                    opacity: 0,
                    x: 30,
                }, {
                    opacity: 1,
                    x: 0,
                    duration: 1,
                    ease: "power1.out",
                    scrollTrigger: {
                        trigger: element, // トリガーとなる要素を指定
                        start: "top 70%", // スクロール開始位置を指定
                        //markers: true,
                    },
                }
            );
        });

        // トップメッセージセクションのアニメーション
        const messageFigures = document.querySelectorAll(".p-top__message-figure-wrap");
        if (messageFigures != null) {
            const figures = document.querySelectorAll(".p-top__message-figure-wrap figure");
            let topMessageMM = gsap.matchMedia();
            let start = "top center";
            // モバイル
            topMessageMM.add("(max-width:767px)", function() {
                start = "top bottom";
            });
            let messageFigureTL = gsap.timeline({
                defaults: {
                    duration: 1,
                },
                scrollTrigger: {
                    trigger: messageFigures,
                    toggleActions: "play none none none",
                    start: start,
                    end: "bottom top",
                },
            });
            messageFigureTL
                .to(figures[0], {
                    "clip-path": "polygon(200% 0%, 0% 200%, 0% 0%)",
                })
                .to(
                    figures[1], {
                        duration: 1.5,
                        "clip-path": "polygon(0% -100%, 200% 100%, 0% 100%)",
                    },
                    "<+0.3"
                )
                .to(
                    figures[2], {
                        "clip-path": "polygon(200% 0%, 0% 200%, 0% 0%)",
                    },
                    "<+0.2"
                )
                .to(
                    figures[3], {
                        "clip-path": "polygon(0% -100%, 200% 100%, 0% 100%)",
                    },
                    "<+0.3"
                );
        }

        /*===========================
        // header color chenge
		===========================*/
        const headerChangeTrigger = document.querySelector(".js-header-change-trigger");
        if (headerChangeTrigger != null) {
            ScrollTrigger.create({
                trigger: headerChangeTrigger,
                endTrigger: "body",
                start: "bottom top",
                end: "bottom bottom-=100%",
                toggleActions: "play pause resume reset",
                toggleClass: { targets: ".header", className: "header--change" },
            });
        }

        /*===========================
		1文字ずつフェード
		===========================*/
        // HTMLのテキストを1文字ずつタグで囲う
        class SpanWrap {
            constructor(target) {
                this.target = this.convertElement(target);
                this.nodes = [...this.target.childNodes];
                this.convert();
            }
            convert() {
                    let spanWrapText = "";
                    this.nodes.forEach((node) => {
                        if (node.nodeType == 3) {
                            //テキストの場合
                            const text = node.textContent.replace(/\r?\n/g, ""); //テキストから改行コード削除
                            //spanで囲んで連結
                            spanWrapText =
                                spanWrapText +
                                text.split("").reduce((acc, v) => {
                                    return acc + `<span class="char">${v}</span>`;
                                }, "");
                        } else {
                            //テキスト以外
                            //<br>などテキスト以外の要素をそのまま連結
                            spanWrapText = spanWrapText + node.outerHTML;
                        }
                    });
                    this.target.innerHTML = spanWrapText;
                }
                //jQueryオブジェクトや文字列セレクターを変換
            convertElement(element) {
                if (element instanceof HTMLElement) {
                    return element;
                }
                if (element instanceof jQuery) {
                    return element[0];
                }
                return document.querySelector(element);
            }
        }
        const targets = [...document.querySelectorAll(".js-text-animation")];

        targets.forEach((target) => {
            new SpanWrap(target);
        });

        // top message のみ
        const topMassageTextWrap = document.querySelector(".js-text-animation-trigger-message");
        if (topMassageTextWrap != null) {
            const topMassageText = topMassageTextWrap.querySelectorAll(".char");
            gsap.set(topMassageText, { display: "inline-block" });
            gsap.from(topMassageText, {
                y: 50,
                scaleY: 0,
                stagger: {
                    each: 0.05,
                },
                scrollTrigger: {
                    trigger: topMassageTextWrap,
                    toggleActions: "play none none none",
                    start: "top top+=80%",
                },
            });
        }

        // top news のみ
        const topNewsTextWrap = document.querySelector(".js-text-animation-trigger-news");
        if (topNewsTextWrap != null) {
            const windowHeight = window.innerHeight;
            const topNewsText = topNewsTextWrap.querySelectorAll(".char");
            gsap.set(topNewsText, { display: "inline-block" });
            gsap.from(topNewsText, {
                y: 50,
                scaleY: 0,
                stagger: {
                    each: 0.05,
                },
                scrollTrigger: {
                    trigger: ".js-top-business-pin",
                    toggleActions: "play none none none",
                    start: `"bottom+=${windowHeight}" top+=90%"`,
                },
            });
        }

        // その他
        const textWrap = document.querySelectorAll(".js-text-animation-trigger");
        if (textWrap != null) {
            textWrap.forEach((wrap) => {
                const textChar = wrap.querySelectorAll(".char");
                gsap.set(textChar, { display: "inline-block" });
                gsap.from(textChar, {
                    y: 50,
                    scaleY: 0,
                    stagger: {
                        each: 0.05,
                    },
                    scrollTrigger: {
                        trigger: wrap,
                        toggleActions: "play none none none",
                        start: "top top+=80%",
                    },
                });
            });
        }

        /*===========================
		// 装飾のアニメーション
		===========================*/
        // 下向き
        const downDecorations = document.querySelectorAll(".js-decoline-down");
        if (downDecorations != null) {
            downDecorations.forEach((deco) => {
                gsap.from(deco, {
                    "clip-path": "inset(0 0 100% 0)",
                    scrollTrigger: {
                        trigger: deco,
                        toggleActions: "play none none none",
                        start: "top top+=80%",
                        pinnedContainer: businessPinArea
                    },
                });
            });
        }

        // 上向き
        const upDecorations = document.querySelectorAll(".js-decoline-up");
        if (upDecorations != null) {
            upDecorations.forEach((deco) => {
                gsap.from(deco, {
                    "clip-path": "inset(100% 0 0 0)",
                    scrollTrigger: {
                        trigger: deco,
                        toggleActions: "play none none none",
                        start: "top top+=80%",
                    },
                });
            });
        }
    });
}