import { fv } from "./_fv";
import { animation } from "./_animation";
import { animationSub } from "./_animationSub";
import { drawer } from "./_drawer";
import { slideProduct } from "./_slideProduct";
import { slideRecruit } from "./_slideRecruit";
import { scrollToTop } from "./_scrollToTop";

// ページの内容が完全に読み込まれた後のイベント
document.addEventListener('DOMContentLoaded', () => {
    // Common function execution
    [scrollToTop, drawer].forEach(fn => {
        try {
            fn();
        } catch (error) {
            console.error("Error executing common function:", error);
        }
    });

    // Main tag ID retrieval
    const PageId = document.querySelector('main')?.getAttribute('id');

    // Page-specific function execution
    const pageFunctions = {
        'top': [animation, fv, slideProduct, slideRecruit],
        'about': [animationSub],
        'business': [animationSub],
        'service': [animationSub],
        'news': [animationSub],
        'contact': [animationSub],
        'sitemap': [animationSub],
        'privacy': [animationSub],
    };

    (pageFunctions[PageId] || []).forEach(fn => {
        if (typeof fn === 'function') {
            try {
                fn();
            } catch (error) {
                console.error(`Error executing function for page ID ${PageId}:`, error);
            }
        } else {
            console.error(`Expected function but got ${typeof fn} for page ID ${PageId}`);
        }
    });
});