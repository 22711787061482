import { Splide } from "@splidejs/splide";
import { AutoScroll } from "@splidejs/splide-extension-auto-scroll";
export function slideRecruit() {
    const recruitSlider01 = document.querySelector("#splide-recruit01");
    if (recruitSlider01) {
        new Splide(recruitSlider01, {
            type: "loop", // ループ
            arrows: false, // 矢印ボタンを非表示
            gap:20,
            autoHeight:true,
            autoWidth:true,
            pagination:false,
            autoScroll: {
              pauseOnHover:false,
              pauseOnFocus:false,
              speed: 0.7,
            },
        }).mount({ AutoScroll });
    }
    const recruitSlider02 = document.querySelector("#splide-recruit02");
    if (recruitSlider02) {
        new Splide(recruitSlider02, {
            type: "loop", // ループ
            arrows: false, // 矢印ボタンを非表示
            gap:20,
            autoHeight:true,
            autoWidth:true,
            pagination:false,
            autoScroll: {
              speed: -0.7,
              pauseOnHover:false,
              pauseOnFocus:false,
            },
        }).mount({ AutoScroll });
    }
}