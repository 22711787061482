import { gsap } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollToPlugin, ScrollTrigger);

export function animationSub() {
    // フッター TOPスクロールボタン
    const scrollTopElement = document.getElementById("js-scrollTop");
    if (scrollTopElement != null) {
        // 最初に要素を非表示に設定
        scrollTopElement.style.display = "none";

        // スクロールイベントリスナーを追加
        window.addEventListener("scroll", function() {
            // 現在のスクロール位置を取得
            const scrollPosition = window.scrollY || document.documentElement.scrollTop;

            // スクロール位置が500pxを超えたら表示、それ以下なら非表示
            if (scrollPosition > 500) {
                scrollTopElement.style.display = "block";
            } else {
                scrollTopElement.style.display = "none";
            }
        });

        // 既存のクリックイベントリスナー（アニメーションのための）
        scrollTopElement.addEventListener("click", () => {
            gsap.to(window, {
                duration: 1,
                scrollTo: {
                    y: 0,
                    autoKill: false,
                },
            });
        });
    }

    

    // フェードインアニメーション
    const fadeInElements = document.querySelectorAll(".js-fade-in");
    fadeInElements.forEach((element, index) => {
        gsap.fromTo(
            element, {
                opacity: 0,
                y: 70,
            }, {
                opacity: 1,
                y: 0,
                duration: .7,
                ease: "power1.out",
                scrollTrigger: {
                    trigger: element, // トリガーとなる要素を指定
                    start: "top-=40 80%", // スクロール開始位置を指定
                    // markers: true,
                },
                delay: index * .5 // 1秒間隔でアニメーションを開始
            }
        );
    });

    // フェードインアニメーション
    const fadeInElementsNews = document.querySelectorAll(".js-fade-in-news");
    const TargetNews = document.querySelectorAll(".p-top__news");
    fadeInElementsNews.forEach((element, index) => {
        gsap.fromTo(
            element, {
                opacity: 0,
                y: 70,
            }, {
                opacity: 1,
                y: 0,
                duration: .7,
                ease: "power1.out",
                scrollTrigger: {
                    trigger: element, // トリガーとなる要素を指定
                    start: "top+=40 80%", // スクロール開始位置を指定
                    //markers: true,
                },
                delay: index * .5 // 1秒間隔でアニメーションを開始
            }
        );
    });

    /*===========================
    // 装飾のアニメーション
    ===========================*/
    // 下向き
    const downDecorations = document.querySelectorAll(".js-decoline-down");
    if (downDecorations != null) {
        downDecorations.forEach((deco) => {
            gsap.from(deco, {
                "clip-path": "inset(0 0 100% 0)",
                scrollTrigger: {
                    trigger: deco,
                    toggleActions: "play none none none",
                    start: "top top+=80%",
                    // pinnedContainer: businessPinArea
                },
            });
        });
    }

    // 上向き
    const upDecorations = document.querySelectorAll(".js-decoline-up");
    if (upDecorations != null) {
        upDecorations.forEach((deco) => {
            gsap.from(deco, {
                "clip-path": "inset(100% 0 0 0)",
                scrollTrigger: {
                    trigger: deco,
                    toggleActions: "play none none none",
                    start: "top top+=80%",
                },
            });
        });
    }
}